<template>
  <b-overlay :show="loading">
    <AuthWrapper>
      <template #card>
        <div class="text-center">
          <div>
            <h2>Welcome Back</h2>
          </div>
          <div>
            <p>Please enter your login credentials</p>
          </div>
        </div>
        <b-alert
            variant="danger"
            show
        >
          <div v-if="error" class="alert-body font-small-2">
            <b-card-text>
              <feather-icon icon="AlertCircleIcon" />
              <small>{{ error }}</small>
            </b-card-text>
          </div>
        </b-alert>
        <validation-observer ref="loginForm" #default="{invalid}">
          <b-form @submit.prevent="login">
            <!--                username -->
            <b-form-group
                label="Email / Phone"
                label-for="login-username"
            >
              <validation-provider
                  #default="{ errors }"
                  name="Email / Phone"
                  vid="username"
                  rules="required"
              >
                <div class="exxtra-input">
                      <span class="leading">
                        <RegistrationMail />
                      </span>
                  <input id="login-username" v-model="username" autocomplete="false"
                         placeholder="Enter your email address or phone"
                  >
                </div>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <!--                password -->
            <b-form-group label="Password" label-for="login-password">

              <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="password"
                  rules="required"
              >
                <div class="exxtra-input">
                      <span class="leading">
                        <PasswordLock />
                      </span>
                  <input id="login-password" v-model="password" autocomplete="false" :type="passwordFieldType"
                         placeholder="*****"
                  >
                  <span class="trailing">
                    <feather-icon class="cursor-pointer" :icon="passwordToggleIcon" @click="togglePasswordVisibility" />
                  </span>
                </div>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
              <div class="d-flex justify-content-end">
                <b-link :to="{ name:'auth-forgot-password' }">
                  <small class="pt-2">Forgot Password?</small>
                </b-link>
              </div>
            </b-form-group>

            <div>
              <b-button
                  class="py-1"
                  type="submit"
                  variant="primary"
                  block
                  :disabled="invalid"
              >
                Sign in
              </b-button>
            </div>
            <div v-if="!isPartner && !isOMC" class="text-center">
              <p class="pt-2"> Dont have an account?
                <span>
                  <b-link :to="{ name:'auth-register' }">
                    <span>Sign Up</span>
                  </b-link>
                </span>
              </p>
            </div>
          </b-form>
        </validation-observer>
      </template>

    </AuthWrapper>
  </b-overlay>
</template>

<script>

// import store from '@/store/index'
import { MUTATE_USER_ABILITIES } from "@/store/config/mutation-keys"
import AuthWrapper from "@core/components/auth-wrapper/AuthWrapper.vue";

import { get } from "lodash"
import { required } from '@validations'
import PasswordLock from "@/assets/svg/PasswordLock.svg";
import AppLogo from '@core/layouts/components/Logo.vue';
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import RegistrationMail from "@/assets/svg/RegistrationMail.svg";
import Rectangles from "@/pages/auth/shared-components/rectangles.vue";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import {
  BImg,
  BForm,
  BLink,
  BAlert,
  BButton,
  BOverlay,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BCardText,
  VBTooltip,
} from 'bootstrap-vue'
/* eslint-disable global-require */
import { ValidationObserver, ValidationProvider } from 'vee-validate'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    AuthWrapper,
    RegistrationMail,
    PasswordLock,
    Rectangles,
    BAlert,
    BLink,
    BOverlay,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BCardText,
    BImg,
    BForm,
    BButton,
    // BAlert,
    AppLogo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      loading: false,
      error: '',
      remember_me: '',
      password: '',
      username: '',
      // validation rules
      required
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    isPartner() {
      const env = process.env.VUE_APP_PLATFORM === "partner";
      return env;
    },
    isOMC() {
      const env = process.env.VUE_APP_PLATFORM === "omc";
      return env;
    },
    homeRoute() {
      if (this.isPartner) {
        return "partner-home";
      } 
      if (this.isOMC) {
        return "omc-home";
      }
      return "client-home";
    }
  },
  created() {
    const { token } = this.$route.query;
    if (token) {
      this.confirmAccount(token)
    }
  },
  methods: {
    async confirmAccount(confirmAccountToken) {
      try {
        this.loading = true;
        if (confirmAccountToken) {
          await this.useJwt().authService.setAccountConfirmationToken(confirmAccountToken);

          const accountConfirmationStatus = await this.useJwt().authService.confirmAccount();
          const { activated, token } = get(accountConfirmationStatus, "data.data", {});

          if (activated) {
            this.useJwt().authService.setToken(token)

            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Success',
                icon: 'CoffeeIcon',
                variant: 'success',
                text: 'Your account has been activated successfully.',
              },
            })

            await this.useJwt().authService.clearAccountConfirmationToken();
            this.$router.push({ name: "auth-register-success" });
          } else {
            this.error = "Your account confirmation token has expired. Please login to get a new confirmation token.";
          }
        }
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            icon: 'CoffeeIcon',
            variant: 'danger',
            text: error_message,
          },
        })

        this.error = error_message;
      } finally {
        this.loading = true;
      }
    },
    async login() {
      try {
        this.loading = true

        const success = await this.$refs.loginForm.validate();
        if (!success) {
          throw new Error("Please check and make sure all fields are valid");
        }
        const response = await this.useJwt()
            .authService
            .login({
              username: this.username,
              password: this.password,
            });

        const response_data = response.data.data;
        const { mfa_auth_required } = response_data;

        // check if user has mfa enabled
        if (mfa_auth_required) {
          // save access token from payload into app jwt auth service
          // retrieve saved user preferred mfa method to proceed on mfa type
          const { mfa_access_token, mfa_method } = response_data;
          this.useJwt().mfaService.setMfaToken(mfa_access_token);

          // check if mfa method is app
          // proceed to authentication app QR code screen if mfa method is app
          // proceed to verify sent mfa otp if mfa method is email/sms
          this.$router.push({
            name: "security-mfa-authentication",
            params: { mfa_method }
          })
              .then(() => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Set-up multi factor authentication',
                    icon: 'CheckCircleIcon',
                    variant: 'success',
                    text: 'Kindly secure your account'
                  },
                });
              })
              .catch(() => {
              });
        } else {
          const {
            access_token,
            user,
            abilities,
            settings
          } = response_data;
          this.useJwt()
              .authService
              .setToken(access_token)
          this.$ability.update(abilities)
          this.$store.commit(`auth/${MUTATE_USER_ABILITIES}`, abilities)

          const days_before_password_expire_reminder = this.getValueFromSource(settings, "security_settings.days_before_password_expire_reminder", 10000)
          const next_route = user.days_before_password_expire <= days_before_password_expire_reminder ? "auth-password-expiration" : this.homeRoute

          this.$router.replace({
            name: next_route,
          })
              .then(() => {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: `Welcome ${user.first_name || user.last_name}`,
                    icon: 'CoffeeIcon',
                    variant: 'success',
                    text: `You have successfully logged in as ${user.user_type}.`,
                  },
                })
              })
              .catch(() => {});
        }
      } catch (error) {
        console.log(error);
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message)
        this.error = error_message
      } finally {
        this.loading = false;
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';

sup {
  font-size: 1rem;
}
</style>
